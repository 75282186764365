
import React, {useState, useEffect} from 'react';
import {
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";
import parse from 'html-react-parser';
import { AnimatePresence, motion } from "framer-motion";

import useSound from 'use-sound';
import soundClick from './assets/sounds/click.mp3';

// STYLES
import './assets/scss/base.scss'
import './assets/scss/menu.scss'
import './assets/scss/home.scss'
import './assets/scss/propos.scss'
import './assets/scss/realisations.scss'
import './assets/scss/contact.scss'
import './assets/scss/mentions.scss'

// COMPONENTS
import Loading from './components/Loading';
import Logo from './components/Logo';
import OverlayMenu from './components/OverlayMenu';
import Mentions from './components/Mentions';

// SVG
import LogoRotpunkt from './assets/svg/logo_rotpunkt.svg';
import LogoArbi from './assets/svg/logo_arbi.svg';

// PNG
import LogoBinova from './assets/png/logo_binova_white.png'
import LogoMiton from './assets/png/logo_miton_white.png'

// PHOTOS A PROPOS
import PhotoBioKh from './assets/photos/bio-kh.jpg'
import PhotoBioMj from './assets/photos/bio-mj.jpg'
import PhotoOffice from './assets/photos/office.jpg'

const Home = () => {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    // componentDidMount()
    useEffect(() => {
        fetch("https://unempreinte.com/cockpit/api/collections/get/home?token=69e3e7f947e2d18355103d27fd6327")
        .then(res => res.json())
        .then(
            (result) => {
            setIsLoaded(true);
            setItems(result.entries);
            },
            // Remarque : il faut gérer les erreurs ici plutôt que dans
            // un bloc catch() afin que nous n’avalions pas les exceptions
            // dues à de véritables bugs dans les composants.
            (error) => {
            setIsLoaded(true);
            setError(error);
            }
        )
    }, [])

    if (error) {
        return <div>Erreur : {error.message}</div>;
    } else if (!isLoaded) {
        return <Loading />
    } else {
        return(
            <motion.section className="home-container" 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity:0 }}
                transition={{ duration: 0.5, type: 'tween' }}
                >

                <div className="home-left">

                    <div className="home-left-container">

                        <div className="ham">
                            <OverlayMenu
                                height='32'
                                width='32'
                                color='#070A0D'
                            />
                        </div>

                        <motion.div className="logo"
                            initial={{ x: 40, opacity:0 }}
                            animate={{ x:0, opacity:1 }}
                            transition={{ delay: 0.6, duration: .4, type: 'tween' }}
                        >
                            <Logo 
                                fill={'#070A0D'}
                                height='71'
                                width='420'
                            />
                        </motion.div>

                        <motion.h5 
                            initial={{ opacity:0 }}
                            animate={{ opacity:1 }}
                            transition={{ delay: 1, duration: .4, type: 'tween' }} 
                            className="title">
                            { items.map( item => (item.subtitle)) }
                            </motion.h5>

                        <motion.h3 
                            initial={{ y:30, opacity:0 }}
                            animate={{ y:0, opacity:1 }}
                            transition={{ delay: 1.4, duration: .4, type: 'tween' }}  className="chapo">
                            { items.map( item => ( parse(item.chapeau) ) ) }
                        </motion.h3>

                        <motion.div className="next"
                            initial={{ y: -30, opacity: 0}}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ delay:1.8, duration: .4, type: 'tween' }}>
                            <Link to="/a-propos">A propos</Link>
                            <div className="line">
                                <svg width="4" height="39" viewBox="0 0 4 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <line x1="2" y1="0.111267" x2="2" y2="38.1113" stroke="#070A0D" strokeWidth="4"/>
                                </svg>
                            </div>
                        </motion.div>

                    </div>

                </div>

                <div className="home-right">

                    <motion.div className="home-right-overlay"
                    initial={{ width: "100%" }}
                    animate={{ width: 0 }}
                    transition={{ delay:2.2, duration: 1, type: 'tween' }}
                    ></motion.div>

                    <img src={ items.map( item => ( "http://unempreinte.com/" + item.photo.path ) ) } alt="qsd"/>
                </div>

            </motion.section>
        )
    }
}

const Propos = () => {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    // componentDidMount()
    useEffect(() => {
        fetch("https://unempreinte.com/cockpit/api/collections/get/propos?token=69e3e7f947e2d18355103d27fd6327")
        .then(res => res.json())
        .then(
            (result) => {
            setIsLoaded(true);
            setItems(result.entries);
            },
            // Remarque : il faut gérer les erreurs ici plutôt que dans
            // un bloc catch() afin que nous n’avalions pas les exceptions
            // dues à de véritables bugs dans les composants.
            (error) => {
            setIsLoaded(true);
            setError(error);
            }
        )
    }, [])

    if (error) {
        return <div>Erreur : {error.message}</div>;
    } else if (!isLoaded) {
        return <Loading />;
    } else {

        return(        
            <motion.section className="propos-container"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity:0 }}
                transition={{ duration: 0.5, type: 'tween' }}
                >
                
                <motion.div className="photo-corner"
                    initial={{ x:20, opacity: 0 }}
                    animate={{ x:0, opacity: 1 }}
                    transition={{ delay:1.2, duration: 1, type: 'tween' }}
                >
                    <img src={PhotoOffice} alt="office"/>
                </motion.div>

                <motion.div className="logo"
                initial={{ y: -20, opacity: 0}}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay:.2, duration: 1, type: 'tween' }}
                >
                    <Logo 
                        fill={'#070A0D'}
                        height='30.36'
                        width='180'
                    />
                </motion.div>

                <div className="ham">
                    <OverlayMenu
                        height='32'
                        width='32'
                        color='#070A0D'
                    />
                </div>

                <motion.div className="propos"
                    initial={{ y: 20, opacity: 0}}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay:.8, duration: 1, type: 'tween' }}
                    >
                    <p>{ items.map( item => ( parse(item.propos) ) ) }</p>
                </motion.div>

                <motion.div className="photo-bio-1"
                    initial={{ y: 20, opacity: 0}}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay:1.6, duration: 1, type: 'tween' }}
                >
                    <img src={PhotoBioKh} alt="Kathya Humbert"/>
                </motion.div>

                <motion.div className="bio-content-1 bio"
                initial={{ y: 20, opacity: 0}}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay:1.4, duration: 1, type: 'tween' }}
                >
                    <h5>Kathya Humbert</h5>
                    <p>{ items.map( item => ( parse(item.kathya) ) ) }</p>
                </motion.div>

                <motion.div className="photo-bio-2"
                    initial={{ y: 20, opacity: 0}}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay:2, duration: 1, type: 'tween' }}
                >
                    <img src={PhotoBioMj} alt="Michel Jacquot"/>
                </motion.div>

                <motion.div className="bio-content-2 bio"
                initial={{ y: 20, opacity: 0}}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay:1.8, duration: 1, type: 'tween' }}
                >
                    <h5>Michel Jacquot</h5>
                    <p>{ items.map( item => ( parse(item.michel) ) ) }</p>
                </motion.div>

                <motion.div className="next"
                    initial={{ y: -30, opacity: 0}}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay:2, duration: 1, type: 'tween' }}>
                    <Link to="/realisations">Réalisations</Link>
                    <div className="line">
                        <svg width="4" height="39" viewBox="0 0 4 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line x1="2" y1="0.111267" x2="2" y2="38.1113" stroke="#070A0D" strokeWidth="4"/>
                        </svg>
                    </div>
                </motion.div>

            </motion.section>
        )
    }
}

const Contact = () => {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    // componentDidMount()
    useEffect(() => {
        fetch("https://unempreinte.com/cockpit/api/collections/get/contact?token=69e3e7f947e2d18355103d27fd6327")
        .then(res => res.json())
        .then(
            (result) => {
            setIsLoaded(true);
            setItems(result.entries);
            },
            // Remarque : il faut gérer les erreurs ici plutôt que dans
            // un bloc catch() afin que nous n’avalions pas les exceptions
            // dues à de véritables bugs dans les composants.
            (error) => {
            setIsLoaded(true);
            setError(error);
            }
        )
    }, [])

    if (error) {
        return <div>Erreur : {error.message}</div>;
    } else if (!isLoaded) {
        return <Loading />
    } else {

        return(
            <motion.section className="contact-container"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity:0 }}
                transition={{ duration: 0.5, type: 'tween' }}
                >

                <div className="contact-photo">
                    <motion.div className="contact-photo-overlay"
                        initial={{ width: "100%" }}
                        animate={{ width: 0 }}
                        transition={{ delay:1.8, duration: 1, type: 'tween' }}
                    ></motion.div>
                    <img src={ items.map( item => ( "http://unempreinte.com/" + item.image.path ) ) } alt="office"/>
                </div>

                <motion.div className="contact-infos"
                    initial={{ y:40, opacity:0 }}
                    animate={{ y: 0, opacity:1 }}
                    transition={{ delay:.2, duration: 1, type: 'tween' }}>
                    <p>{ items.map( item => ( parse(item.text) ) ) }</p>
                </motion.div>

                <div className="contact-ham">
                    <OverlayMenu
                        height='32'
                        width='32'
                        color='#070A0D'
                    />
                </div>

                <motion.div className="contact-footer"></motion.div>

                <motion.div className="contact-adresse"
                    initial={{ y:30, opacity:0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: .6, duration: 1, type: 'tween' }}
                >

                    <Logo 
                        fill={'#FFFFFF'}
                        height='20.24'
                        width='120'
                    />

                    <p>
                        { items.map( item => ( parse(item.adresse) ) ) }
                        { items.map( item => ( parse(item.email) ) ) }
                        <span>horaires sur rendez-vous :</span><br/>
                        { items.map( item => ( item.horaires ) ) }
                    </p>
                </motion.div>

                <div className="contact-partenaires">
                    <motion.a 
                        initial={{ opacity:0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 1, duration: .4, type: 'tween' }}
                        href="https://www.rotpunktkuechen.de/fr/cuisines.html"
                    >
                        <img src={LogoRotpunkt} alt="RotPunkt" />
                    </motion.a>

                    <motion.a 
                        initial={{ opacity:0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 1.4, duration: .4, type: 'tween' }}
                        href="https://www.arbiarredobagno.fr/"
                    >
                        <img src={LogoArbi} alt="Arbi" />
                    </motion.a>

                    <motion.a 
                        initial={{ opacity:0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 1.8, duration: .4, type: 'tween' }}
                        href="https://fr.binova.it/"
                    >
                        <img src={LogoBinova} alt="Binova" />
                    </motion.a>

                    <motion.a 
                        initial={{ opacity:0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 2.24, duration: .4, type: 'tween' }}
                        href="https://miton.it/"
                    >
                        <img src={LogoMiton} alt="Miton" />
                    </motion.a>
                </div>

                <div className="contact-mentions">
                    <Mentions />{ items.map( item => ( parse(item.mentions) ) ) }
                </div>
            </motion.section>
        )
    }
}

// 404 PAGE
const NotFound = () => {
    return(
        <motion.div 
        initial={{ y:20, opacity:0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: .2, duration: .6, type: 'tween' }}
        className="not-found">
            <Logo 
                fill={'#070A0D'}
                height='60'
                width='300'
            />
            <h5>404 | Cette page n'existe pas</h5>
            <a href='/'>Revenir à l'accueil</a>
        </motion.div>
    )
}

// REALISATIONS
const Gallery = () => {

    // states fetch
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    // states data
    const [items, setItems] = useState([]); // data storage issu du fetch
    const [gallery, setGallery] = useState([]); // data storage issu du fetch
    const [indexPhoto, setIndexPhoto] = useState(0); // stock current index, qui sert a faire correspondre nos classes pour afficher les photos unes a unes.
    const [nbPhotos, setNbPhotos] = useState(0); //stock le nombre de photos de la gallerie en cours d'affichage
    const [idProjet, setIdProjet] = useState(0); // stock l'identifiant du projet cliqué, qui sert a faire correspondre nos classes pour afficher quel projet est actif ou pas.

    const [sound] = useSound(
        soundClick, { volume: 0.6, playbackRate:2.5  }
    );
    
    const handleClick = (projet) => {
        sound(); // play sound
        // on stock le data du projet cliqué dans un state.
        setGallery(projet);
        // on stock l'id du projet cliqué
        setIdProjet(projet._id);
        // on compte le nombre de photos dans la gallerie du projet cliqué et on le stock dans un state.
        setNbPhotos(Object.keys(projet.gallerie).length -1);
        // enfin on reset lindex photo afin que la nouvelle gallerie commence avec la première photo de sa série.
        setIndexPhoto(0);
    }

    const viewer = ( index ) => {
        // methode de correspondance des index des photos pour la navigations
        // (arrows) et equivalance pour les toggle class ? visible : hidden
        if(  index < 0) {
            setIndexPhoto(0)
        }
        else if (  index > nbPhotos ) {
           setIndexPhoto(nbPhotos)
        }
        else {
            setIndexPhoto(index)
        }
    }

    // componentDidMount()
    useEffect(() => {
        fetch("https://unempreinte.com/cockpit/api/collections/get/projets?token=69e3e7f947e2d18355103d27fd6327") // derniere entrée
        .then(res => res.json())
        .then(
            (result) => {
            setItems(result.entries); // get result from cockpit.
            setGallery(result.entries[0]); // on set par defaut le premier projet afin d'afficher la premiere gallerie
            setIdProjet(result.entries[0]._id) // on set par defaut l'identifiant du premier projet pour passer en actif le nom dans la liste.
            setNbPhotos(Object.keys(result.entries[0].gallerie).length -1) // on definit par defaut le nombre de photos que contient le projet par defaut
            setIsLoaded(true); // all is good, loading is over.
            },
            // Remarque : il faut gérer les erreurs ici plutôt que dans
            // un bloc catch() afin que nous n’avalions pas les exceptions
            // dues à de véritables bugs dans les composants.
            (error) => {
            setIsLoaded(true);
            setError(error);
            }
        )
    }, [])

    if (error) {
        return <div>Erreur : {error.message}</div>;
    } else if (!isLoaded) {
        return <Loading />
    } else {
        return(
            <motion.div className="showcase-container"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity:0 }}
                transition={{ duration: 0.5, type: 'tween' }}
                >

                <motion.div className="showcase-logo" 
                    initial={{ x: -20, opacity: 0}}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay:.2, duration: 1, type: 'tween' }}>
                    <Logo 
                        fill={'#FFFFFF'}
                        height='30.36'
                        width='180'
                    />
                </motion.div>

                <div className="showcase-ham">
                    <OverlayMenu
                        height='32'
                        width='32'
                        color='#FFFFFF'
                    />
                </div>

                <motion.div className="showcase-description"
                    initial={{ y: 20, opacity: 0}}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay:.6, duration: 1, type: 'tween' }}>
                    { // description (meta) des photos gallerie
                        gallery.gallerie.map( 
                            (photos, index) => <h5 key={"meta-" + index} className={indexPhoto === index ? "description-visible" : "description-hidden"} >{photos.meta.title}</h5>
                        )
                    }
                </motion.div>
                
                <motion.div className="showcase-gallery"
                    initial={{ x: 20, opacity: 0}}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay:1, duration: 1, type: 'tween' }}>
                    {// gallerie data path
                    gallery.gallerie.map( (photos, index) => 
                        <img
                            key={"key-" + index}
                            className={indexPhoto === index ? "showcase-visible" : "showcase-hidden"} 
                            src={ "http://unempreinte.com/" + photos.path } 
                            alt={"photo-" + index} />
                    )}
                </motion.div>

                <motion.div className="showcase-nav"   
                    initial={{ x: -20, opacity: 0}}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay:1.4, duration: 1, type: 'tween' }}>
                    <div className="showcase-command">
                        {/* BACK */}
                        <svg 
                        onClick={ () => viewer(indexPhoto - 1) }
                        className="arrow-back" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25 13.75H9.7875L16.775 6.7625L15 5L5 15L15 25L16.7625 23.2375L9.7875 16.25H25V13.75Z" fill="white"/>
                        </svg>

                        {/* LINE */}
                        <svg width="60" height="30" viewBox="0 0 60 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line y1="1" x2="60" y2="1" stroke="white" strokeWidth="2"/>
                        </svg>

                        {/* COMPTEUR */}
                        <span>0{indexPhoto + 1} / 0{nbPhotos + 1}</span>

                        {/* FORWARD */}
                        <svg 
                        onClick={ () => viewer(indexPhoto + 1) }
                        className="arrow-fwd" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 5L13.2375 6.7625L20.2125 13.75H5V16.25H20.2125L13.2375 23.2375L15 25L25 15L15 5Z" fill="white"/>
                        </svg>
                    </div>
                </motion.div>

                <motion.div className="showcase-list"
                    initial={{ x: -20, opacity: 0}}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay:1.8, duration: 1, type: 'tween' }}>
                    <ul>
                    {   // PROJETS LISTS
                        items.map( projet => <li onClick={ ()=>handleClick( projet )} 
                            key={projet._id}
                            className={projet._id === idProjet ? "active" : null}>
                                {projet.name}
                            </li>)
                    }
                    </ul>
                </motion.div>
                
                <motion.div className="showcase-next"
                    initial={{ y: -30, opacity: 0}}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay:2, duration: 1, type: 'tween' }}>
                    <Link to="/contact">Contact</Link>
                    <div className="line">
                        <svg width="4" height="39" viewBox="0 0 4 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line x1="2" y1="0.111267" x2="2" y2="38.1113" stroke="#070A0D" strokeWidth="4"/>
                        </svg>
                    </div>
                </motion.div>

            </motion.div>
        )
    }
} 

// APP
const App = () => {

    const location = useLocation();

    return(
        <AnimatePresence exitBeforeEnter>
            <Switch location={ location } key={ location.key }>

                <Route exact path="/">
                    <Home />
                </Route>

                <Route exact path="/a-propos">
                    <Propos />
                </Route>

                <Route exact path="/realisations">
                    <Gallery />
                </Route>

                <Route exact path="/contact">
                    <Contact />
                </Route>

                <Route path="*">
                    <NotFound />
                </Route>


            </Switch>
        </AnimatePresence>
    )
}

export default App;