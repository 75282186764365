const Logo = (props) => {

    return(
        <svg width={props.width} height={props.height} viewBox="0 0 420 71" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.8709 18.3296V56.6149H27.6705V48.4004C26.537 51.1141 24.8034 53.1677 22.5364 54.6346C20.2694 56.1748 17.6024 56.9083 14.5353 56.9083C10.0014 56.9083 6.46755 55.5147 3.8672 52.7277C1.26684 49.9406 0 45.9067 0 40.6994V18.3296H3.33379V40.406C3.33379 44.7332 4.33393 47.9604 6.3342 50.234C8.33447 52.5076 11.1349 53.6078 14.802 53.6078C18.7359 53.6078 21.803 52.2876 24.1366 49.5739C26.4036 46.8602 27.5371 43.2664 27.5371 38.7191V18.3296H30.8709Z" fill={props.fill}/>
            <path d="M60.4029 17.7523C64.8035 17.7523 68.3373 19.1458 70.9376 21.9329C73.538 24.7933 74.8048 28.9005 74.8048 34.2546V56.6243H70.0709V34.768C70.0709 30.7341 69.1374 27.727 67.3371 25.6001C65.5369 23.4731 62.9365 22.4463 59.5361 22.4463C55.7356 22.4463 52.8018 23.6931 50.6015 26.1135C48.4012 28.6071 47.2677 32.0543 47.2677 36.3815V56.6243H42.5338V18.0457H47.0677V25.16C48.3345 22.813 50.1348 20.9794 52.4684 19.6592C54.7354 18.4124 57.4025 17.7523 60.4029 17.7523Z" fill={props.fill}/>
            <path d="M117.091 37.4031C117.091 37.9165 117.025 38.65 116.958 39.5301H88.2874C88.6874 42.9772 90.0876 45.6909 92.4213 47.7446C94.7549 49.8715 97.622 50.8983 101.089 50.8983C105.29 50.8983 108.69 49.3581 111.291 46.2043L114.824 50.7516C113.224 52.8053 111.224 54.3455 108.89 55.4456C106.49 56.5458 103.823 57.0592 100.889 57.0592C97.1552 57.0592 93.8214 56.1791 90.9544 54.4922C88.0873 52.8786 85.8203 50.4583 84.2868 47.4512C82.6866 44.4441 81.8865 40.997 81.8865 37.1831C81.8865 33.4426 82.6866 29.9954 84.2201 26.9884C85.687 23.9813 87.8206 21.561 90.5543 19.8741C93.2214 18.1872 96.2218 17.3804 99.6222 17.3804C103.023 17.3804 106.023 18.1872 108.69 19.8741C111.291 21.561 113.357 23.9813 114.891 26.9884C116.358 29.9954 117.091 33.5159 117.091 37.4031ZM99.6222 23.3212C96.5552 23.3212 94.0215 24.348 91.9545 26.4016C89.8876 28.4552 88.6207 31.1689 88.2874 34.4694H110.957C110.624 31.1689 109.424 28.5286 107.357 26.4016C105.29 24.348 102.689 23.3212 99.6222 23.3212Z" fill={props.fill}/>
            <path d="M168.282 17.0211C172.75 17.0211 176.35 18.4146 178.95 21.275C181.551 24.2088 182.884 28.4627 182.884 34.1835V56.6266H174.55V35.357C174.55 31.9098 173.883 29.3428 172.483 27.5826C171.083 25.8957 169.149 25.0156 166.549 25.0156C163.748 25.0156 161.481 26.0424 159.814 28.0226C158.148 30.0763 157.281 33.01 157.281 36.7505V56.6266H148.946V35.357C148.946 31.9098 148.28 29.3428 146.879 27.5826C145.479 25.8957 143.546 25.0156 140.945 25.0156C138.078 25.0156 135.878 26.0424 134.211 28.0226C132.544 30.0029 131.677 32.9366 131.677 36.7505V56.6266H123.343V17.4612H131.277V22.4485C132.611 20.6883 134.278 19.3681 136.278 18.4146C138.278 17.4612 140.478 17.0211 142.945 17.0211C145.612 17.0211 148.013 17.5345 150.08 18.6347C152.147 19.8082 153.747 21.4217 154.947 23.5487C156.414 21.4951 158.281 19.8815 160.615 18.708C162.948 17.6079 165.482 17.0211 168.282 17.0211Z" fill={props.fill}/>
            <path d="M212.092 16.5723C215.425 16.5723 218.493 17.379 221.226 19.0659C223.96 20.7528 226.094 23.1732 227.627 26.1803C229.161 29.2607 229.961 32.8545 229.961 36.8884C229.961 40.9223 229.161 44.5161 227.627 47.5232C226.094 50.6036 223.96 53.024 221.226 54.6375C218.493 56.3978 215.425 57.2045 212.092 57.2045C207.491 57.2045 203.891 55.591 201.29 52.4372V70.8464H190.889V17.159H200.823V21.7063C203.424 18.2592 207.158 16.5723 212.092 16.5723ZM210.291 47.8166C212.958 47.8166 215.159 46.8631 216.892 44.8095C218.559 42.8292 219.426 40.1889 219.426 36.8884C219.426 33.5879 218.559 30.8742 216.892 28.894C215.159 26.9137 212.958 25.9602 210.291 25.9602C207.624 25.9602 205.424 26.9137 203.757 28.894C202.024 30.8742 201.157 33.5879 201.157 36.8884C201.157 40.1889 202.024 42.8292 203.757 44.8095C205.424 46.8631 207.624 47.8166 210.291 47.8166Z" fill={props.fill}/>
            <path d="M246.235 21.6406C247.502 19.807 249.235 18.4868 251.302 17.5333C253.369 16.6532 255.703 16.2131 258.37 16.2131V28.7549C257.236 28.6082 256.236 28.5348 255.503 28.5348C252.769 28.5348 250.702 29.3416 249.169 30.9552C247.635 32.5687 246.835 35.0624 246.835 38.3629V56.6254H234.167V16.8732H246.235V21.6406Z" fill={props.fill}/>
            <path d="M297.574 37.0351C297.574 37.1818 297.507 38.2086 297.374 40.1155H270.237C270.704 42.5359 271.904 44.5161 273.704 45.9097C275.504 47.3032 277.771 48.0366 280.505 48.0366C282.372 48.0366 284.039 47.7432 285.506 47.0832C286.906 46.4964 288.239 45.5429 289.506 44.2228L295.04 50.8237C291.64 55.0776 286.706 57.2046 280.238 57.2046C276.171 57.2046 272.637 56.3244 269.504 54.5642C266.37 52.8773 263.969 50.457 262.303 47.3765C260.636 44.2961 259.769 40.7756 259.769 36.8884C259.769 33.0012 260.636 29.5541 262.303 26.4003C263.903 23.3199 266.236 20.8995 269.17 19.1393C272.037 17.4524 275.304 16.5723 278.905 16.5723C282.439 16.5723 285.572 17.379 288.44 19.0659C291.307 20.7528 293.507 23.0998 295.174 26.1803C296.774 29.334 297.574 32.9279 297.574 37.0351ZM278.972 25.2268C276.638 25.2268 274.638 25.9602 273.037 27.4271C271.437 28.894 270.437 30.8742 270.104 33.4413H287.773C287.439 30.9476 286.439 28.9673 284.839 27.4271C283.239 25.9602 281.305 25.2268 278.972 25.2268Z" fill={props.fill}/>
            <path d="M303.18 17.4557H311.514V56.6211H303.18V17.4557ZM307.38 11.0015C305.847 11.0015 304.58 10.4881 303.58 9.38796C302.58 8.36115 302.046 7.04097 302.046 5.50076C302.046 3.96055 302.58 2.64037 303.58 1.54021C304.58 0.513406 305.847 0 307.38 0C308.914 0 310.181 0.513407 311.181 1.46687C312.181 2.49368 312.714 3.74052 312.714 5.28073C312.714 6.89429 312.248 8.21447 311.247 9.31462C310.247 10.4148 308.914 11.0015 307.38 11.0015Z" fill={props.fill}/>
            <path d="M339.925 17.3804C344.392 17.3804 347.992 18.7739 350.659 21.6343C353.26 24.4947 354.593 28.7486 354.593 34.2494V56.6191H348.192V35.0561C348.192 31.3156 347.392 28.4552 345.725 26.5483C344.059 24.6414 341.725 23.6879 338.658 23.6879C335.191 23.6879 332.457 24.7881 330.457 26.9884C328.456 29.262 327.456 32.4158 327.456 36.5964V56.6191H321.055V17.7471H327.19V23.6146C328.456 21.6343 330.257 20.0941 332.457 18.9939C334.657 17.8938 337.124 17.3804 339.925 17.3804Z" fill={props.fill}/>
            <path d="M383.447 54.2735C382.58 55.1536 381.447 55.8137 380.18 56.2537C378.846 56.7671 377.446 56.9872 376.046 56.9872C372.779 56.9872 370.245 56.0337 368.445 54.0534C366.645 52.0732 365.778 49.3594 365.778 45.7656V22.4424H359.51V18.0418H365.778V9.6073H370.512V18.0418H381.18V22.4424H370.512V45.4722C370.512 47.7459 371.045 49.5061 372.112 50.6796C373.112 51.9265 374.646 52.5132 376.579 52.5132C377.579 52.5132 378.513 52.3665 379.446 51.9998C380.313 51.6331 381.113 51.193 381.78 50.5329L383.447 54.2735Z" fill={props.fill}/>
            <path d="M419.933 38.3635H389.996C390.063 41.3706 390.796 44.0109 392.063 46.2846C393.33 48.6316 395.13 50.4652 397.33 51.712C399.531 52.9588 401.998 53.6189 404.731 53.6189C406.998 53.6189 409.132 53.1789 411.065 52.2254C412.999 51.3453 414.599 50.0251 415.866 48.2649L417.8 50.6852C416.266 52.6655 414.399 54.2057 412.132 55.3058C409.799 56.406 407.332 56.9194 404.665 56.9194C401.197 56.9194 398.13 56.1126 395.397 54.4257C392.663 52.7388 390.596 50.4652 389.062 47.5314C387.529 44.5977 386.729 41.2239 386.729 37.4834C386.729 33.7429 387.462 30.4424 388.929 27.4353C390.329 24.5016 392.33 22.2279 394.863 20.541C397.397 18.8541 400.264 18.0474 403.398 18.0474C406.531 18.0474 409.399 18.8541 411.932 20.4677C414.399 22.1546 416.399 24.4282 417.866 27.362C419.267 30.2957 420 33.5962 420 37.3367L419.933 38.3635ZM403.398 21.2745C400.931 21.2745 398.73 21.8612 396.797 23.0347C394.863 24.2816 393.263 25.9685 392.129 28.0954C390.929 30.2957 390.263 32.7894 390.063 35.5031H416.733C416.6 32.7894 415.933 30.2957 414.733 28.1688C413.532 26.0418 411.932 24.3549 409.999 23.1081C408.065 21.8612 405.865 21.2745 403.398 21.2745Z" fill={props.fill}/>
        </svg>
    )
}

export default Logo;