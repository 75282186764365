import React, { Fragment, useState } from 'react';
import { NavLink } from "react-router-dom";

import useSound from 'use-sound';
import soundClick from '../assets/sounds/click.mp3'

const OverlayMenu = (props) => {
    
    // TOGGLE STATE :: OPEN / CLOSE
    const [menuIsVisible, setMenuIsVisible] = useState(false);

    const [sound] = useSound(
            soundClick, { volume: 0.8, playbackRate:1.5  }
        );
    return(
        <Fragment>

            <svg // HAM ICON
            onClick={ () => {
                setMenuIsVisible(!menuIsVisible);
                sound();
            } }
            width={props.height} 
            height={props.width} 
            viewBox="0 0 40 24" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg">
                <line y1="2" x2="40" y2="2" stroke={props.color} strokeWidth="4"/>
                <line y1="12" x2="36" y2="12" stroke={props.color} strokeWidth="4"/>
                <line y1="22" x2="32" y2="22" stroke={props.color} strokeWidth="4"/>
            </svg>

            <div // OVERLAY MENU
            className={ menuIsVisible ? "fade-in menu is-visible" : "fade-out is-hidden"} 
            onClick={ () => setMenuIsVisible(!menuIsVisible)}
            >
            <nav>
                <NavLink exact to="/" activeClassName="current"><span></span>Home</NavLink>
                <NavLink to="/a-propos" activeClassName="current"><span></span>A propos</NavLink>
                <NavLink to="/realisations" activeClassName="current"><span></span>Réalisations</NavLink>
                <NavLink to="/contact" activeClassName="current"><span></span>Contact</NavLink>
            </nav>
        </div>

        </Fragment>
    )
}

export default OverlayMenu;