import React, { Component } from 'react';
import '../assets/scss/loading.scss'

class Loading extends Component {
    render() {
        return (
            <div className="loader loader--style5" title="4">
            <svg className="loading" width="30px" height="40px" viewBox="0 0 24 30">
                <rect x="0" y="0" width="4" height="10" fill="#333">
                <animateTransform attributeType="xml"
                    attributeName="transform" type="translate"
                    values="0 0; 0 20; 0 0"
                    begin="0" dur="0.8s" repeatCount="indefinite" />
                </rect>
                <rect x="10" y="0" width="4" height="20" fill="#333">
                <animateTransform attributeType="xml"
                    attributeName="transform" type="translate"
                    values="0 0; 0 20; 0 0"
                    begin="0.2s" dur="0.8s" repeatCount="indefinite" />
                </rect>
                <rect x="20" y="0" width="4" height="15" fill="#333">
                <animateTransform attributeType="xml"
                    attributeName="transform" type="translate"
                    values="0 0; 0 20; 0 0"
                    begin="0.4s" dur="0.8s" repeatCount="indefinite" />
                </rect>
            </svg>
            </div>
        );
    }
}

export default Loading;