import React, { Fragment, useState } from 'react';

const Mentions = () => {
    
    // TOGGLE STATE :: OPEN / CLOSE
    const [menuIsVisible, setMenuIsVisible] = useState(false);

    return(
        <Fragment>
            <span className="span-inline" onClick={ () => setMenuIsVisible(!menuIsVisible)}>Mentions Legales</span>

            <div className={ menuIsVisible ? "mentions-fade-in mentions mentions-visible" : "mentions mentions-hidden"} 
                onClick={ () => setMenuIsVisible(!menuIsVisible)}>
                
                <div className="mentions-content">

                    <h2>Mentions légales</h2>

                    <h3>Editeur du Site :</h3>

                    <p><strong>Unempreinte</strong><br/>
                        Responsable editorial : Kathya Humbert<br/>
                        7 rue Ducis, 78000 Versailles<br/>
                        Téléphone :0139509560 - Fax : 0139509560<br/>
                        Email : contact@unempreinte.com<br/>
                        Site Web : <a href="http://http://unempreinte.com/">http://unempreinte.com/</a><br/>
                        Forme juridique de la société : SARL<br/>
                        Numéro de SIRET :  49248725100042<br/>
                        Montant du capital social : 1040,00€
                    </p>

                    <h3>Hébergement :</h3>

                    <p><strong>Namecheap, Inc.</strong><br/>
                        4600 East Washington Street Suite 305 Phoenix, AZ 85034 USA<br/>
                        Site Web :  <a href="http://https://www.namecheap.com/">https://www.namecheap.com/</a>
                    </p>

                    <h3>Développement :</h3>

                    <p><strong>Sisyphe Digital</strong><br/>
                        5 avenue Ingres, 75116 Paris<br/>
                        Site Web : <a href="http://https://sisyphe.digital/">https://sisyphe.digital/</a><br/>
                        Email : contact@sisyphe.digital
                    </p>

                </div>
                
            </div>
        </Fragment>
        
    )
}

export default Mentions;